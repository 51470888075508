const BaseURL = 'https://app-end-portal.taller24e.org/api';

const titles = {
  siteName: 'Taller 24E',
}

const logos = {
  logoTopbar: 'icon_logo.png',
  logoLogin: 'logo_24EME_color.png',
  backgroundLogin: 'login_background.jpg',
}

export {
  BaseURL,
  titles,
  logos
}
